import React from "react"
import Thanks from "../components/Thanks"
import Layout from "../components/Layout"

const seoProps = {
  title: "Thank you",
  description: "Thank you for getting in touch",
  image: "/img/sawing1.jpg",
  pathname: "/thanks",
}

const ThanksPage = () => (
  <Layout seoProps={seoProps}>
    <Thanks />
  </Layout>
)

export default ThanksPage
