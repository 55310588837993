import React, { Component } from "react"

class Thanks extends Component {
  render() {
    return (
      <div id="thanks" className="section-spacing">
        <div className="sectionHeading">
          <hr />
          <h4 className="is-size-3 centerText">
            Thanks for getting in touch, <br /> We will get back to you soon.
          </h4>
          <hr />
        </div>
      </div>
    )
  }
}

export default Thanks
